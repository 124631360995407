<template>
<div>
  <v-container v-if="loaded">
    <v-row justify="center">
      <v-col cols="12">
        <v-btn to="/">Back</v-btn>
      </v-col>
      <v-col cols="12" sm="8">
        <v-card>
          <v-card-title>Order Details</v-card-title>
          <v-card-text>
            <div class="d-flex">
              <div class="label"><b>UUID:</b> </div>
              <div>{{ order.uuid }}</div>
            </div>
            <div class="d-flex">
              <div class="label"><b>Order Date:</b> </div>
              <div>{{ order.created_at }}</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-title>Images</v-card-title>
          <v-card-text>
            <h5>Cropped:</h5>
            <a :href="`/storage/cropped/${order.logo}`" target="_blank"><v-img :src="`/storage/cropped/${order.logo}`"></v-img></a>

            <h5>Original:</h5>
            <a :href="order.original" target="_blank"><v-img :src="order.original" max-width="100"></v-img></a>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="mt-5">
          <v-card-title>Files</v-card-title>
          <v-card-text>
            <div v-for="(item, docIndex) in order.documents">
              <span class="label">
                <b>Color:</b>
                <v-chip :color="item.color" :dark="['red', 'green', 'black'].includes(item.color)" small>
                  {{ item.color }}
                </v-chip> <br>
                Type: {{ item.type }} <br>
                Tickets: {{item.total_tickets}} <br>

                Covers: <v-btn color="indigo" :loading="coverLoading[docIndex]" @click="getCoverPDF(item.id)" dark>PDF Covers</v-btn> - <v-btn outlined :href="`/admin/print/generate/cover/${ order.uuid }/${ item.total_tickets }/${ item.color }/0`" target="_blank">HTML Covers</v-btn>
              </span>
              <br>
              <div>
                Box labels: <v-btn color="indigo" :loading="labelsLoading[docIndex]" @click="getLabelsPDF(item.id)" dark>PDF Labels</v-btn> - <v-btn outlined :href="`/admin/print/generate/box-labels/${ order.uuid }/${ item.total_tickets }/${ item.color }/0`" target="_blank">HTML Labels</v-btn>
              </div>
              <div v-for="(i, index) in (item.total_tickets/1000)" class="mt-2">
                <v-btn @click="downloadPdfOnDemand(order.uuid, item.total_tickets, item.color, i - 1, `${docIndex}-${index}`, item.type)" :loading="fileDownloadLoading[`${docIndex}-${index}`]" color="indigo" dark>PDF File: {{ i }}</v-btn>
                <v-btn outlined target="_blank" :href="`/admin/print/generate/${ item.type }/${ order.uuid }/${ item.total_tickets }/${ item.color }/${i - 1}`">
                  HTML File: {{ i }}
                </v-btn>
              </div>
              <v-divider></v-divider>
            </div>
            <div class="mt-5">
              <file-generator :uuid="order.uuid" :order="order" @new-files-generated="getOrderInfo"></file-generator>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import FileGenerator from "./FileGenerator"
export default {
  name: "order-view",
  components: {
    FileGenerator
  },
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      fileDownloadLoading: {},
      coverLoading: {},
      labelsLoading: {},
      order: {}
    }
  },
  created() {
    this.getOrderInfo()
  },
  methods: {
    getOrderInfo() {
      axios.post(`/admin/get-order-info`, {
        uuid: this.uuid
      }).then((response) => {
        this.order = response.data
        this.loaded = true
      })
    },
    getCoverPDF(documentId, id) {
      this.$set(this.coverLoading, id, true)

      axios.post(`/admin/document/${documentId}/cover/generate-pdf`, {
        uuid: this.uuid
      }).then((response) => {
        this.$set(this.coverLoading, id, false)
        window.open('/admin/print/download/' + response.data.url)
      })
    },
    getLabelsPDF(documentId, id) {
      this.$set(this.labelsLoading, id, true)

      axios.post(`/admin/document/${documentId}/box-labels/generate-pdf`, {
        uuid: this.uuid
      }).then((response) => {
        this.$set(this.coverLoading, id, false)
        window.open('/admin/print/download/' + response.data.url)
      })
    },
    downloadPdfOnDemand(uuid, total_tickets, color, page, id, type) {
      this.$set(this.fileDownloadLoading, id, true)

      axios.post(`/admin/document/get-pdf-on-demand`, {
        uuid,
        ticketValue: total_tickets,
        color,
        page,
        type
      }).then((response) => {
        this.$set(this.fileDownloadLoading, id, false)
        window.open('/admin/print/download/' + response.data.url)
      })
    }
  }
}
</script>

<style scoped>
  .label {
    text-transform: capitalize;
    color: #333;
    font-size: 100%;
  }

  /deep/ .v--modal {
    box-shadow: 0 20px 60px -2px rgb(27 33 58 / 40%);
  }
</style>
