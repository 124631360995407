import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)


import Dashboard from './components/Dashboard.vue'
import OrderView from './components/order/order-view'

const routes = [
	{ path: '/', component: Dashboard },
	{ path: '/order/:uuid', component: OrderView, props: true},
]

const router = new VueRouter({
	base: '/app',
	mode: 'history',
	routes // short for `routes: routes`
})

export {router};
