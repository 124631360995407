/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'

const {router} = require('./router.js');

import Vuetify from 'vuetify'
Vue.use(Vuetify)

import VModal from 'vue-js-modal'
Vue.use(VModal)

import 'vuetify/dist/vuetify.min.css'

const app = new Vue({
  router: router,
	vuetify : new Vuetify({
		icons: {
			iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
		},
	}),
  el: '#app'
});
