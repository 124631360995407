<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card>
          <v-data-table :headers="headers" :items="items" :options="options">
            <template #item.type="{item}">
              {{ item.type == 1 ? 'Front' : 'Back' }}
            </template>

            <template #item.actions="{item}">
              <v-btn :to="`/order/${item.uuid}`" color="indigo" dark elevation="0">More</v-btn>
            </template>
          </v-data-table>

<!--          <v-data-table
            v-bind:headers="headers"
            :items="items"
            hide-default-footer
            class="elevation-1"
            :options.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.id }}</td>
              <td class="text-xs-right">{{ props.item.uuid }}</td>
              <template v-slot:item.type="props">
              </template>
              <td class="text-xs-right"><a :href="props.item.original"><v-icon>file_download</v-icon></a></td>
              <td class="text-xs-right"><a :href="'/storage/cropped/' + props.item.logo"><v-icon>file_download</v-icon></a></td>
              <td class="text-xs-right">{{ props.item.ip }}</td>
              <td class="text-xs-right">{{ props.item.agent }}</td>
              <td class="text-xs-right">{{ props.item.country }}</td>
              <td class="text-xs-right">{{ props.item.created_at }}</td>
              <td class="text-xs-right">
                <template v-if="props.item.type == 1">
                  <v-btn primary class="btn btn-success" @click="download(props.item.uuid, 'generate-front')">Generate Print Files</v-btn>
                </template>
                <template v-else>
                  <v-btn primary class="btn btn-success" @click="download(props.item.uuid, 'generate-back')">Generate Print Files</v-btn>
                </template>
              </td>
              <td class="text-xs-right"><v-btn :to="`/order/${props.item.uuid}`" color="red">More</v-btn></td>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pagination.pages"></v-pagination>
          </div>-->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped>
</style>

<script>
  export default {
    components: {},
    data: function () {
      return {
        headers: [
          {
            text: '#',
            align: 'left',
            sortable: true,
            value: 'id'
          },
          { text: 'UUID', value: 'uuid' },
          { text: 'Type', value: 'type'},
          // { text: 'Logo Original', value: 'logo' },
          // { text: 'Logo Cropped', value: 'logo_c' },
          // { text: 'IP', value: 'ip' },
          // { text: 'User Agent', value: 'agent' },
          // { text: 'Country', value: 'country' },
          { text: 'Date', value: 'created_at' },
          { text: 'Actions', value: 'actions'}
        ],
        items: [],
        options: { page: 1, itemsPerPage: 25, totalItems: 0, pages: 1},
      }
    },
    methods: {
      init(){
        axios.post('/admin/clients', {}).then((response) => {
          this.items = response.data;
          this.options.pages = Math.ceil(response.data.length/25);
        });
      },
    },
    mounted() {
      this.init();
    }
  }
</script>
