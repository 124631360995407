<template>
<div>
  <div>
    <template>
      <template v-if="order.type == 1">
        <v-btn primary class="btn btn-success" @click="download(order.uuid, 'generate-front')">Generate Print Files</v-btn>
      </template>
      <template v-else>
        <v-btn primary class="btn btn-success" @click="download(order.uuid, 'generate-back')">Generate Print Files</v-btn>
      </template>
    </template>
  </div>
  <modal name="generate-front" height="auto" width="620px">
    <div class="modal-wrapper">
      <div>

        <v-alert type="error" v-if="error">
          {{ error }}
        </v-alert>

        <v-select
            :items="ticketValues"
            v-model="downloadRequest.ticketValue"
            label="Select number of tickets"
            single-line
            bottom
        ></v-select>

        <div v-if="downloadRequest.ticketValue == 10000">
          <v-radio-group v-model="downloadRequest.colorVariations">
            <v-radio :value="1" label="1 Color"></v-radio>
            <v-radio :value="5" label="5 Colors"></v-radio>
          </v-radio-group>
        </div>

        <div v-if="downloadRequest.ticketValue == 25000">
          <v-radio-group v-model="downloadRequest.colorVariations">
            <v-radio :value="3" label="3 Colors (2x10 000 + 1x 5000)"></v-radio>
            <v-radio :value="5" label="5 Colors (5x10 000)"></v-radio>
          </v-radio-group>
        </div>

        <div v-if="downloadRequest.ticketValue == 50000">
          <v-radio-group v-model="downloadRequest.colorVariations">
            <v-radio :value="5" label="5 Colors (5x10 000)"></v-radio>
          </v-radio-group>
        </div>

        <template v-if="showColorSelector">
          <template v-for="(color, index) in downloadRequest.color">
            <v-select
                :items="colors"
                :value="color"
                @change="(value) => $set(downloadRequest.color, index, value)"
                label="Select color"
                single-line
                bottom
            ></v-select>
          </template>
        </template>


        <v-checkbox
            v-model="downloadRequest.withForm"
            label="Vordruck"
        ></v-checkbox>

        <v-btn primary @click="downloadFile()" v-if="downloadURL == '' && !loading" color="indigo" dark>Generate Print Files</v-btn>

        <v-alert v-if="loading" type="info">
          Generating, please wait.
        </v-alert>
        <p v-if="downloadURL != ''">
          <v-alert type="info">
          Files are ready!
          </v-alert>
<!--          <template v-for="(url, index) in downloadURL">-->
<!--            <a :href="url" target="_blank" >Download page {{index + 1}}</a> <br>-->
<!--          </template>-->
        </p>

      </div>
    </div>
  </modal>


  <modal name="generate-back" height="auto" width="620px">
    <div class="modal-wrapper">
      <div>
        <v-select
            :items="ticketValues"
            v-model="downloadRequest.ticketValue"
            label="Select number of tickets"
            single-line
            bottom
        ></v-select>

        <v-btn primary @click="downloadBack()" v-if="downloadURL == '' && !loading">Generate Print Files</v-btn>
        <p v-if="loading">Print process started, please chek back later.</p>
        <a :href="downloadURL" target="_blank" v-if="downloadURL != ''">Download</a>
      </div>
    </div>
  </modal>
</div>
</template>

<script>
export default {
  name: "FileGenerator",
  props: {
    uuid: {
      type: String,
      required: true
    },
    order: {
      type: Object,
    }
  },
  computed: {
    showColorSelector() {
      if (this.downloadRequest.colorVariations === 5) {
        return false;
      }

      return true;
      return ([1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000].includes(this.downloadRequest.ticketValue)) || (this.downloadRequest.ticketValue === 10000 && this.downloadRequest.colorVariations === 1);
    }
  },
  data() {
    return {
      error: '',
      loading: false,
      ticketValues: [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 25000, 50000],
      colors: ['yellow', 'red', 'green', 'blue', 'black'],
      pagination: { sortBy: 'id', page: 1, rowsPerPage: 25, descending: true, totalItems: 0, pages: 1},
      downloadRequest: {
        ticketValue: 0,
        color: [],
        uuid: '',
        withForm: false,
        colorVariations: 1
      },
      downloadURL: ''
    }
  },
  watch: {
    'downloadRequest.ticketValue': function (value) {
      this.downloadRequest.color = [''];

      if (value === 25000) {
        this.downloadRequest.colorVariations = 3;
      }

      if (value === 50000) {
        this.downloadRequest.colorVariations = 5;
      }
    },
    'downloadRequest.colorVariations': function (value) {
      this.downloadRequest.color = [];
      for (let i = 0; i < value; i++) {
        this.downloadRequest.color.push('');
      }
    }
  },
  methods: {
    download(uuid, modal){
      this.downloadRequest.uuid = uuid;
      this.show(modal);
      this.downloadURL = '';
    },
    downloadFile() {
      if (this.downloadRequest.ticketValue == 0) {
        this.error = 'Please select number of tickets.'
        return;
      }

      if (this.downloadRequest.color.length == 0) {
        this.error = 'Please select color.'
        return;
      }

      if ([1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000].includes(this.downloadRequest.ticketValue) && this.downloadRequest.color.filter(Boolean).length == 0) {
        this.error = 'Please select color.'
        return;
      }

      this.loading = true;

      axios.post('/admin/print', this.downloadRequest).then((response) => {
        this.downloadURL = response.data.url;
        this.newFilesGenerated()
      }).catch((error) => {
        this.error = error.response.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    downloadBack() {
      this.loading = true;
      axios.post('/admin/print/back', this.downloadRequest).then((response) => {
        this.downloadURL = '/admin/print/download/' + response.data.url;
        this.newFilesGenerated()
        this.hide(modal);
      }).catch((error) => {
        this.error = error.response.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    show (modal) {
      this.$modal.show(modal);
    },
    hide (modal) {
      this.$modal.hide(modal);
    },
    newFilesGenerated() {
      this.$emit('new-files-generated');
    }
  }
}
</script>

<style scoped>

</style>
